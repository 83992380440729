import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import LogRocket from "logrocket";
import setupLogRocket from "logrocket-react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { MSAL_APP_KEYS } from "./authentication/msalAppKeys";
import { store } from "./redux/store";
import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";

LogRocket.init(`${process.env.REACT_APP_LOG_ROCKET_PROJECT}`, {
  console: { shouldAggregateConsoleErrors: true }
});
setupLogRocket(LogRocket);

const msalConfig = {
  auth: {
    clientId: MSAL_APP_KEYS.INSPECT_APP_ID,
    authority: MSAL_APP_KEYS.AUTHORITY,
    redirectUri: MSAL_APP_KEYS.ACTIVATION_CALLBACK_URL,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

(async () => {
  try {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_FLAGS_CLIENT_SIDE_ID || ""
    });

    const msalInstance = new PublicClientApplication(msalConfig);

    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );

    root.render(
      <React.StrictMode>
        <LDProvider>
          <Provider store={store}>
            <BrowserRouter>
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
            </BrowserRouter>
          </Provider>
        </LDProvider>
      </React.StrictMode>
    );
  } catch (e: any) {
    console.log({ e });
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
