import React, { useCallback, useContext } from "react";
import {
  SCAN_FREQUENCIES,
  SCAN_FREQUENCIES_CONSTANTS
} from "./components/scan-frequency/scans-frequency.constants";
import ModalContext from "../contexts/modal.context";

import ChangeScanFrequencyModalInfo from "./components/scan-frequency/change-scan-frequency-modal-info";
import useSettingsModel from "./settings.model";
import SnackbarContext from "../contexts/snackbar.context";
import { MESSAGE } from "../designSystem/snackbar/snackbar.types";
import { INFORMATION } from "../constants/images";

export default function UseSettings() {
  const {
    scanFrequency,
    loading,
    orgName,
    tenantId,
    currentOrganizationId,
    nextScan,
    lastScan,
    onUpdateScanFrequency
  } = useSettingsModel();
  const { showModal } = useContext(ModalContext);

  const { showSnackbar } = useContext(SnackbarContext);

  const showChangeFrequencyModal = useCallback(
    (frequencyOption: any) =>
      showModal({
        title: `${SCAN_FREQUENCIES_CONSTANTS["change scan frequency"]}`,
        actionText: "Confirm",
        isForm: true,
        clickAction: async () => {
          onUpdateScanFrequency(frequencyOption, () => {
            showSnackbar({
              text: `Scan Frequency succesfully updated`,
              type: MESSAGE.info,
              icon: INFORMATION
            });
          });
        },
        content: (
          <ChangeScanFrequencyModalInfo
            frequencyOption={frequencyOption}
            currentFrecuency={scanFrequency}
            orgName={orgName}
          />
        )
      }),
    [showModal, scanFrequency, orgName, onUpdateScanFrequency, showSnackbar]
  );

  return {
    scanFrequenciesOptions: SCAN_FREQUENCIES,
    orgId: currentOrganizationId,
    tenantId,
    orgName,
    loading,
    scanFrequency,
    nextScan,
    lastScan,
    showChangeFrequencyModal
  };
}
