import React from "react";
import { Link } from "react-router-dom";
import { IScanSummary } from "../scan.types";
import Chip from "../../designSystem/chip/chip";
import { FINDING_STATUS } from "../../finding/finding.types";
import { SHAPES } from "../../designSystem/chip/chip.constants";
import SkeletonLoader from "../../designSystem/skeleton/skeleton-loader";
import SKELETON_VARIANT from "../../designSystem/skeleton/skeleton.constants";
import { URL_INSPECTORS, URL_INSPECTORS_MSP } from "../../constants/appUrls";
import { buildUrl } from "../../utils/string.utils";
import Tooltip from "../../designSystem/tooltip/tooltip";

type SummaryProps = {
  summary: IScanSummary;
  isLoading: boolean;
  orgName: string;
  loadingOrganizations: boolean;
  orgId?: string;
  date?: string | number;
};

function getTooltipText(
  pausedInspectors: number,
  dateInfo: number | string | undefined
) {
  const date = dateInfo || "";
  if (pausedInspectors === 0) {
    return `No inspectors were paused in this scan: ${date}`;
  }
  const isPlural = pausedInspectors >= 2;
  return `${pausedInspectors} inspector${isPlural ? "s" : ""} ${
    isPlural ? "were" : "was"
  } paused in this scan: ${date}`;
}

export default function Summary({
  orgName,
  summary: { findings, inspectors, severity, status, totalIgnoredInspectors },
  isLoading,
  loadingOrganizations,
  orgId,
  date
}: SummaryProps) {
  const tooltipPausedInspectorsText = getTooltipText(
    totalIgnoredInspectors,
    date
  );
  return (
    <section className="scan__summary">
      <div className="scan__summary__header">
        <h5>Summary</h5>
        <SkeletonLoader loadingVar={loadingOrganizations}>
          <h6>{orgName}</h6>
        </SkeletonLoader>
      </div>
      <div className="scan__totals">
        <div className="scan__inspectors">
          <label className="label label--bold">Total inspectors</label>
          <SkeletonLoader loadingVar={isLoading}>
            <h2>{inspectors}</h2>
          </SkeletonLoader>
        </div>
        <Link
          to={orgId ? buildUrl(URL_INSPECTORS_MSP, orgId) : URL_INSPECTORS}
          className="scan__paused-inspectors-clickable"
        >
          <div className="scan__paused-inspectors has-tooltip">
            <label className="label label--bold">Paused inspectors</label>
            <SkeletonLoader loadingVar={isLoading}>
              <p className="label scan__paused-inspectors-link">
                Manage inspectors
              </p>
              <h2>{totalIgnoredInspectors}</h2>

              <Tooltip
                top
                text={tooltipPausedInspectorsText}
                additionalClassName="scan-summary-tooltip"
              />
            </SkeletonLoader>
          </div>
        </Link>
        <div className="scan__count">
          <label className="label label--bold">Total findings</label>
          <SkeletonLoader loadingVar={isLoading}>
            <h2>{findings}</h2>
          </SkeletonLoader>
        </div>
      </div>
      <div className="scan__severity">
        <label className="label label--bold">Findings severity</label>
        <div className="scan__severity__totals">
          <div className="scan__severity__critical">
            <label>Critical</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.critical}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--critical" />
          </div>
          <div className="scan__severity__high">
            <label>High</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.high}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--high" />
          </div>
          <div className="scan__severity__medium">
            <label>Medium</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.medium}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--medium" />
          </div>
          <div className="scan__severity__low">
            <label>Low</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.low}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--low" />
          </div>
          <div className="scan__severity__informational">
            <label>Informational</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.informational}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--informational" />
          </div>
        </div>
      </div>
      <div className="scan__status">
        <label className="label label--bold">Status</label>
        <div className="scan__status__totals">
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.chip}
          >
            <Chip
              label={<label>{status?.new} | New</label>}
              status={FINDING_STATUS.new}
              shape={SHAPES.OVAL}
            />
          </SkeletonLoader>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.chip}
          >
            <Chip
              label={<label>{status?.unchanged} | Unchanged</label>}
              status={FINDING_STATUS.unchanged}
              shape={SHAPES.OVAL}
            />
          </SkeletonLoader>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.chip}
          >
            <Chip
              label={<label>{status?.modified} | Modified</label>}
              status={FINDING_STATUS.modified}
              shape={SHAPES.OVAL}
            />
          </SkeletonLoader>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.chip}
          >
            <Chip
              label={<label>{status?.remediated} | Remediated</label>}
              status={FINDING_STATUS.remediated}
              shape={SHAPES.OVAL}
            />
          </SkeletonLoader>
        </div>
      </div>
    </section>
  );
}
