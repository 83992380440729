/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import "./settings.scss";
import { SETTINGS } from "../constants/images";
import Header from "../designSystem/header/header";
import Icon from "../designSystem/icon/icon";
import TenantInformation from "../profile/components/tenantInformation/tenantInformation";
import ScansFrequency from "./components/scan-frequency/scans-frequency";
import useSettings from "./settings.view.model";

export default function Settings() {
  const {
    scanFrequenciesOptions,
    loading,
    showChangeFrequencyModal,
    scanFrequency,
    nextScan,
    lastScan,
    tenantId,
    orgName
  } = useSettings();

  return (
    <>
      <Header
        icon={<Icon image={SETTINGS} alt="Settings" />}
        title="Settings"
      />
      <TenantInformation
        tenantId={tenantId}
        orgName={orgName}
        additionalClassName="settings__tenant"
        isLoading={loading}
      />
      <ScansFrequency
        scanFrequencies={scanFrequenciesOptions}
        frequencyValue={scanFrequency}
        nextScanValue={nextScan}
        lastScanValue={lastScan}
        onSelectedFrequency={(val) => {
          showChangeFrequencyModal(val);
        }}
        isLoading={loading}
      />
    </>
  );
}
